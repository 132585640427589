<header class="header">
  <div class="container">
    <div class="header__main">
      <img class="cogney-logo" src="../../assets/Cogney-Logo.svg" alt="Cogney SEO Intelligence">
      <ul class="header-menu">
        <li><a href="#home">Home</a></li>
        <li><a href="#features">Features</a></li>
        <li><a href="#pricing">Pricing</a></li>
      </ul>
    </div>
    <button type="button" class="login-with-google-btn" (click)="login()">
      Sign in with Google
    </button>
  </div>
</header>
<section id="home" class="hero text-center">
  <div class="container">
    <h1>Never miss an important ranking change</h1>
    <button type="button" class="login-with-google-btn" (click)="login()">
      Sign up with Google
    </button>
    <video autoplay class="sankey-diagram" loop muted>
      <source src="../../assets/rank-shift.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <!-- <img src="../../assets/Sankey-diagram.jpg" class="sankey-diagram" alt=""> -->
  </div>
</section>
<section class="logos-section">
  <div class="container">
    <h5>Powering the world’s best SEO teams</h5>
    <div class="logos">
      <img src="../../assets/mc-logo.svg" alt="Monte Carlo">
      <img src="../../assets/bitwave.webp" alt="Bitwave">
      <img src="../../assets/logo-color-rollbar.svg" alt="Rollbar">
      <img src="../../assets/valr-logo.svg" alt="VALR">
      <img src="../../assets/logo-wristcheck.webp" alt="Wristcheck">
    </div>
  </div>
</section>
<section id="features" class="features-section">
  <div class="container">
    <div class="features-section__heading">
      <h6>Features</h6>
      <h3>Turn ranking shifts into opportunities</h3>
    </div>
    <div class="features">
      <div class="feature-item">
        <img src="../../assets/lost-clicks.webp" class="feature-item__visual" alt="">
        <div>
          <h3>Keywords that lost clicks</h3>
          <p>Sort by severity and drill down to see which pages contributed to the loss.</p>
        </div>
      </div>
      <div class="feature-item">
        <img src="../../assets/first-clicks.webp" class="feature-item__visual" alt="">
        <div>
          <h3>Keywords that received their first click</h3>
          <p>Discover when a keyword starts driving clicks so you can optimize the page ranking for that keyword further or create new pages targeting tangential keywords.</p>
        </div>
      </div>
      <div class="feature-item">
        <img src="../../assets/Sankey-diagram.jpg" class="feature-item__visual" alt="">
        <div>
          <h3>Every keyword’s ranking movement visualized</h3>
          <p>Select a date range and see how keywords move between ranking buckets: #1-3, 4-10, 11-20, or became unranked. Drill down to see metrics.</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="pricing" class="pricing-section">
  <div class="container">
    <div class="pricing-card">
      <div class="pricing-card__head">
        <div>
          <strong class="pricing-card__name">
            Pricing as simple <br/>as it gets
            <!-- <strong class="pricing-card__badge">Popular</strong> -->
          </strong>
          <!-- <p>Our most popular plan for small teams.</p> -->
        </div>
        <strong class="pricing-card__price">
          <sup>$</sup>25<small>/ mo</small>
        </strong>
      </div>
      <div class="pricing-card__body">
        <h4>Get all the features below plus many more on our roadmap </h4>
        <ul class="pricing-card__advantages">
          <li>Unlimited websites</li>
          <li>Unlimited keywords</li>
          <li>Visualize ranking changes</li>
          <li>Drill down into keyword and page metrics</li>
          <li>Automatic prioritization of keywords that lost clicks</li>
          <li>Identify keywords that had their first clicks</li>
        </ul>
      </div>
      <div class="pricing-card__footer">
        <button type="button" class="login-with-google-btn" (click)="login()">
          Sign up with Google
        </button>
      </div>
    </div>
  </div>
</section>

  <!-- <img class="cogney-logo" src="../../assets/Cogney-Logo.svg" alt="Cogney SEO Intelligence">
  <h1 class="text-center">Which of your keywords are improving or dropping in rank?</h1>
  <h4 class="text-center motto mt-2">Discover your ranking movements, discover where to optimize.</h4>
  <div class="container mt-4">
    <div class="row">
      <div class="col text-center">
        <button type="button" class="login-with-google-btn" (click)="login()">
          Sign in with Google
        </button>
      </div>
    </div>
  </div>
  <div class="text-center pt-4 ps-2 pe-2">
    <img src="../../assets/Sankey-diagram.jpg" class="sankey-diagram" alt="">
  </div> -->

