import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationsService } from 'src/shared/services/notifications.service';
import { Component, Output, EventEmitter } from "@angular/core";
import { Router } from '@angular/router';

@Component({
  selector: 'login-message-component',
  template: `
    <span>{{ message }}</span>
    <button
      style="background-color: transparent; border: 1px solid white; color: white;"
      (click)="onLogin()"
    >
      Login
    </button>
  `,
})
export class AuthInterceptorMessage {
  @Output() public ignore: EventEmitter<undefined> = new EventEmitter();
  constructor(private router: Router) {}
  public message = 'You need to login again! ';
  onLogin() {
    this.router.navigateByUrl('/login');
  }
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private notificationsService: NotificationsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          console.error('auth itcp', error);
          this.notificationsService.showNotification(AuthInterceptorMessage,'error',20000,true);
        }
        return throwError(error);
      })
    );
  }
}
