// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'cogney-gsc',
    appId: '1:490025653752:web:f3ccb7a9f3b7eb6e54720d',
    storageBucket: 'cogney-gsc.appspot.com',
    apiKey: 'AIzaSyA7972waZx0jrdfcpzRB4CSG8n7c6WcRjI',
    authDomain: 'cogney-gsc.firebaseapp.com',
    messagingSenderId: '490025653752',
  },
  useEmulators: false, // Change to true if you want to use Functions local on emulator
  production: false,
  GAPI_CLIENT_ID: '490025653752-d3f1tkihskpf8a6m6a2ujbgbds42h1sc.apps.googleusercontent.com',
  stripePublishableKey: 'pk_live_51Jjti0S15wNQo0wy37xcQ5R0K8KTxYnvJfhNMOEDL1zFjOsbz6Wz4q5kMIgygnEhTHmPj9u8UyYAjInzzbAFDClk00TMKtdq8a', // andrei.cogney test stripe: 'pk_test_51NfLSdICQroUokLXM1TS1LE3Ss2ot6pThkvJw3pE8Myk5E0NWoiXEO8bmf63IU7aAm2kT3ACx5qp6aRCIfCObob500X8ZCJ5Ba'
  firebaseConfig : {
      apiKey: "AIzaSyA7972waZx0jrdfcpzRB4CSG8n7c6WcRjI",
      authDomain: "cogney-gsc.firebaseapp.com",
      projectId: "cogney-gsc",
      storageBucket: "cogney-gsc.appspot.com",
      messagingSenderId: "490025653752",
      appId: "1:490025653752:web:3587c4773b1ef0e754720d"
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
