<div class="container-fluid">
  <div class="row">
    <div
      class="mobile-header d-flex align-items-center justify-content-between"
    >
      <a class="navbar-brand" routerLink="/properties">
        <img src="../../assets/Cogney-Logo.svg" class="logo" alt="Cogney" />
      </a>
      <button
        [icon]="mobileMenuOpen ? 'x' : 'menu'"
        kendoButton
        fillMode="clear"
        class="burger"
        (click)="mobileMenuOpen = !mobileMenuOpen"
      ></button>
    </div>
    <nav
      class="sidebar d-flex flex-column gap-5 bg-light py-3 "
      [class]="{
        'sidebar--open': mobileMenuOpen,
        'sidebar--collapsed': !isSidebarOpen
      }"
    >
      <div class="flex-grow-1">
        <div
          class="d-flex align-items-center justify-content-between mb-4 top-panel"
        >
          <a
            class="navbar-brand d-inline-block align-top"
            routerLink="/properties"
            *ngIf="isSidebarOpen"
          >
            <img src="../../assets/Cogney-Logo.svg" class="logo" alt="Cogney" />
          </a>
          <button
            [icon]="isSidebarOpen ? 'chevron-double-left' : 'chevron-double-right'"
            kendoButton
            fillMode="clear"
            (click)="toggleFullSidebar()"
          ></button>
        </div>

        <form class="search-form" (submit)="handleSearchQueryChange()">
          <kendo-textbox
            placeholder="Search Properties"
            [(ngModel)]="searchQuery"
            [ngModelOptions]="{ standalone: true }"
            (focus)="isSidebarOpen = true"
            (valueChange)="handleSearchQueryChange()"
          ></kendo-textbox>
          <button
            kendoButton
            icon="search"
            fillMode="clear"
            class="search-form__search-btn"
          ></button>
          <button
            *ngIf="searchQuery.length"
            kendoButton
            type="button"
            fillMode="clear"
            icon="x-circle"
            themeColor="error"
            class="search-form__close-btn"
            (click)="resetFilters()"
          ></button>
        </form>

        <h3 *ngIf="isSidebarOpen" class="h5 mb-3">Properties</h3>
        <ul class="properties-list">
          <li kendoTooltip [title]="'Add property'" position="right" [showOn]="isSidebarOpen ? 'none' : 'hover'">
            <button
              kendoButton
              fillMode="clear"
              icon="plus-outline"
              class="properties-list__add indented"
              routerLink="/select-property"
            >
              Add property
            </button>
          </li>
          <li *ngFor="let property of filteredPropertiesKeys.value">
            <app-property-item
              [collapsed]="!isSidebarOpen"
              [domain]="getPropertyDomain(property)"
              [selected]="property === selectedProperty"
              (select)="handleSelectProperty(property)"
            >
            </app-property-item>
            <ul *ngIf="property === selectedProperty" class="navbar-links">
              <li kendoTooltip [title]="'Rankings changes'" position="right" [showOn]="isSidebarOpen ? 'none' : 'hover'">
                <a
                  (click)="navigateTo('rankings')"
                  [ngClass]="{ active: urlContains('rankings') }"
                  [href]="getLinkHref('rankings')"
                >
                  <svg fill="currentColor" viewBox="0 0 100 100">
                    <path
                      d="M78.95,69a7.09,7.09,0,0,0,.86-1A7,7,0,0,0,81,64V56a7,7,0,0,0-1.19-3.91,7.09,7.09,0,0,0-.86-1q-.24-.24-.5-.45A7,7,0,0,0,74,49H70c-3.16,0-5.37-2.26-8.33-6.42L60.55,41H74a7,7,0,0,0,7-7V26a7,7,0,0,0-7-7H26a7,7,0,0,0-7,7v8a7,7,0,0,0,7,7h4c3.93,0,6.42,3.46,10.85,10.1.78,1.17,1.6,2.39,2.47,3.64l.68,1-1.07.51A26.43,26.43,0,0,1,30,59H26a7,7,0,0,0-7,7v8a7,7,0,0,0,7,7h4a50.08,50.08,0,0,0,24.92-6.16C59.54,72.53,62.6,71,70,71h4a7,7,0,0,0,4.45-1.6C78.62,69.26,78.79,69.11,78.95,69ZM75,25V35H56.5l-.3-.42a50.31,50.31,0,0,0-6.71-7.85L47.63,25ZM30,35H25V25h5c12.68,0,18.78,9.15,24.16,17.23,1.39,2.08,3,4.43,4.54,6.39l1,1.26-1.58.35a46,46,0,0,0-7.91,2.52l-.73.31L49,52.41c-1.15-1.59-2.22-3.17-3.2-4.64C41.08,40.64,37.32,35,30,35ZM75,65H70c-8.82,0-13,2.07-17.76,4.47C47.05,72.06,41.18,75,30,75H25V65h5c8.82,0,13-2.07,17.76-4.47C52.95,57.94,58.82,55,70,55h5Z"
                    />
                  </svg>
                  Rankings changes
                </a>
              </li>
              <li kendoTooltip [title]="'Lost Clicks'" position="right" [showOn]="isSidebarOpen ? 'none' : 'hover'">
                <a
                  (click)="navigateTo('lost-clicks', true)"
                  [ngClass]="{ active: urlContains('lost-clicks') }"
                  [href]="getLinkHref('lost-clicks', true)"
                >
                  <svg fill="currentColor" viewBox="0 0 24 24">
                    <path
                      fill-rule="evenodd"
                      d="M.364 4.364a1.241 1.241 0 0 1 1.755 0l6.57 6.57 3.26-3.26a1.241 1.241 0 0 1 1.756 0l9.931 9.931a1.241 1.241 0 1 1-1.755 1.755l-9.053-9.053-3.26 3.26a1.241 1.241 0 0 1-1.756 0L.364 6.12a1.241 1.241 0 0 1 0-1.755Z"
                      clip-rule="evenodd"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M22.759 10.62c.685 0 1.241.557 1.241 1.242v6.62c0 .686-.556 1.242-1.241 1.242h-6.621a1.241 1.241 0 1 1 0-2.483h5.38v-5.379a1.24 1.24 0 0 1 1.24-1.241Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Lost Clicks
                </a>
              </li>
              <li kendoTooltip [title]="'First Clicks'" position="right" [showOn]="isSidebarOpen ? 'none' : 'hover'">
                <a
                  (click)="navigateTo('first-clicks', true)"
                  [ngClass]="{ active: urlContains('first-clicks') }"
                  [href]="getLinkHref('first-clicks', true)"
                >
                  <svg fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M12 1.5a6.758 6.758 0 0 0-6.75 6.75v7.5a6.75 6.75 0 0 0 13.5 0v-7.5A6.758 6.758 0 0 0 12 1.5Zm5.25 14.25a5.25 5.25 0 1 1-10.5 0v-7.5a5.25 5.25 0 1 1 10.5 0v7.5Z"
                    />
                    <path
                      d="M12 6a.75.75 0 0 0-.75.75v3a.75.75 0 1 0 1.5 0v-3A.75.75 0 0 0 12 6Z"
                    />
                  </svg>
                  First Clicks
                </a>
              </li>
              <li
                kendoTooltip
                [title]="'First Impressions on Page 1'"
                position="right"
                [showOn]="isSidebarOpen ? 'none' : 'hover'"
              >
                <a
                  (click)="navigateTo('impressions', true)"
                  [ngClass]="{ active: urlContains('impressions') }"
                  [href]="getLinkHref('impressions', true)"
                >
                  <svg fill="currentColor" viewBox="0 0 24 24">
                    <path
                      fill-rule="evenodd"
                      d="M7.196 7.363a4.768 4.768 0 0 1 3.295 1.33 7.297 7.297 0 0 0 0 6.97 4.8 4.8 0 0 1-1.548.988c1.26-1.805.576-4.387-1.404-5.33-1.932-.918-4.283.11-4.95 2.142-.84-3.02 1.466-6.1 4.607-6.1Zm-2.4 7.221c0-1.203 2.4-1.203 2.4 0 0 1.205-2.4 1.205-2.4 0Zm8.708-5.891c2.172-2.067 5.763-1.61 7.357.926 1.582 2.516.442 5.945-2.316 7.032 1.228-1.758.618-4.258-1.267-5.256-1.936-1.028-4.403-.014-5.086 2.068a4.782 4.782 0 0 1 1.312-4.77Zm2.094 7.096c-1.2 0-1.2-2.408 0-2.408s1.2 2.408 0 2.408ZM7.195 19.4a7.16 7.16 0 0 0 4.802-1.85c4.56 4.1 12.003.738 12.003-5.373 0-6.11-7.443-9.472-12.003-5.372C8.176 3.368 1.915 5.07.34 9.954-1.14 14.546 2.387 19.4 7.194 19.4Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  First Impressions on Page 1
                </a>
              </li>
              <li
                kendoTooltip
                [title]="'Change in Clicks'"
                position="right"
                [showOn]="isSidebarOpen ? 'none' : 'hover'"
              >
                <a
                  (click)="navigateTo('change-in-clicks', false)"
                  [ngClass]="{ active: urlContains('change-in-clicks') }"
                  [href]="getLinkHref('change-in-clicks', false)"
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="currentColor">
                  <path
                    d="M15.5909 1.75C12.6209 1.75 10.2109 4.16 10.2109 7.13C10.2109 10.1 12.6209 12.51 15.5909 12.51C18.5609 12.51 20.9709 10.1 20.9709 7.13C20.9709 4.16 18.5609 1.75 15.5909 1.75Z" />
                  <path
                    d="M6.3593 13.0312C4.5293 13.0312 3.0293 14.5213 3.0293 16.3613C3.0293 18.2013 4.5193 19.6913 6.3593 19.6913C8.1893 19.6913 9.6893 18.2013 9.6893 16.3613C9.6893 14.5213 8.1893 13.0312 6.3593 13.0312Z"/>
                  <path
                    d="M16.6205 16.6211C15.0705 16.6211 13.8105 17.8811 13.8105 19.4311C13.8105 20.9811 15.0705 22.2411 16.6205 22.2411C18.1705 22.2411 19.4305 20.9811 19.4305 19.4311C19.4305 17.8811 18.1705 16.6211 16.6205 16.6211Z"/>
                </svg>
                Change in Clicks
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <ul class="navbar-links">
        <li
          class="d-flex align-items-center justify-content-between gap-2 flex-column"
          kendoTooltip
          [title]="'Subscription'"
          position="right"
          [showOn]="isSidebarOpen ? 'none' : 'hover'"
        >
          <button
            kendoButton
            fillMode="clear"
            icon="folder-open"
            class="indented"
            routerLink="/subscription"
          >
          Subscription
          </button>
        </li>
        <li
          class="d-flex align-items-center justify-content-between gap-2 flex-column"
          kendoTooltip
          [title]="'Logout'"
          position="right"
          [showOn]="isSidebarOpen ? 'none' : 'hover'"
        >
          <button
            [icon]="isSidebarOpen ? 'user' : 'logout'"
            kendoButton
            title="Logout"
            fillMode="clear"
            (click)="handleLogoutClick()"
          >
            <span
              class="d-flex align-items-center gap-1 w-100 justify-content-between "
            >
              <span class="indented-span">{{ googleDisplayName }}</span>
              <span class="k-icon k-i-logout" *ngIf="isSidebarOpen"></span>
            </span>
          </button>
        </li>
      </ul>
    </nav>

    <div
      class="page-wrapper"
      [ngClass]="{ 'page-wrapper--expanded': !isSidebarOpen }"
    >
      <div class="container-fluid ms-sm-auto py-3">
        <ng-content #default></ng-content>
      </div>
    </div>
  </div>
  <kendo-dialog
    title="Please confirm"
    *ngIf="confirmDialogOpened"
    (close)="confirmDialogOpened = false"
    [minWidth]="250"
    [width]="450"
  >
    <p style="margin: 30px; text-align: center">
      Are you sure you want to logout?
    </p>
    <kendo-dialog-actions>
      <button kendoButton (click)="confirmDialogOpened = false">No</button>
      <button kendoButton (click)="logout()" themeColor="primary">Yes</button>
    </kendo-dialog-actions>
  </kendo-dialog>
</div>
