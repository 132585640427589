import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import {
  DiagramFilter,
  KeywordHistoryChart,
} from '../interfaces/keyword-data.interface';
import { AuthService } from './auth.service';
import { NotificationsService } from './notifications.service';
import { addDays } from 'date-fns';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';

declare let gapi;
declare let google;

@Injectable({
  providedIn: 'root',
})
export class GoogleRequestsService {
  // This flag is changed when the google api client is loaded
  isGoogleClientLoaded = false;
  rowLimit = 25000;

  constructor(
    private authService: AuthService,
    private notificationsService: NotificationsService,
    private router: Router,
    private fns: AngularFireFunctions
  ) {
    console.info('GoogleRequestsService');
    gapi.load('client', () => {
      console.info('GoogleClientLoaded');
      this.isGoogleClientLoaded = true;
    });
  }

  

  async listSites() {
    try {
      google.accounts.id.initialize({
        client_id: environment.GAPI_CLIENT_ID,
        callback: (response: any) => this.handleGoogleSignIn(response)
      });
      await this.init(this.authService.getAccessToken());
      console.info('GoogleRequestsService listSites');
      const sitesResponse = await gapi.client.webmasters.sites.list();
      
      // Get available sites that user has access to (excluding unverified)
      let accessibleSites = [];
      if (sitesResponse && sitesResponse.result.siteEntry) {
        accessibleSites = sitesResponse.result.siteEntry.filter(
          (site) => site.permissionLevel !== 'siteUnverifiedUser'
        );
        
        // Check if there are any properties the user no longer has access to
        const auth = getAuth();
        if (auth.currentUser) {
          const userEmail = auth.currentUser.email;
          const db = getFirestore();
          
          try {
            // Get user's saved properties from Firestore
            const userPropertiesDoc = doc(db, 'properties', userEmail);
            const userPropertiesSnap = await getDoc(userPropertiesDoc);
            
            if (userPropertiesSnap.exists()) {
              const userData = userPropertiesSnap.data();
              const savedSites = userData.sitesUrl || [];
              
              // Normalize URLs by removing trailing slashes for comparison
              const normalizeUrl = (url: string) => url.endsWith('/') ? url.slice(0, -1) : url;
              const accessibleSiteUrls = accessibleSites.map(site => normalizeUrl(site.siteUrl));
              
              // Find properties user no longer has access to (use normalized URLs for comparison)
              const removedSites = savedSites.filter(site => 
                !accessibleSiteUrls.includes(normalizeUrl(site))
              );
              
              // If there are properties to remove
              if (removedSites.length > 0) {
                // Update user's properties to remove inaccessible sites
                const updatedSites = savedSites.filter(site => 
                  accessibleSiteUrls.includes(normalizeUrl(site))
                );
                await updateDoc(userPropertiesDoc, {
                  sitesUrl: updatedSites
                });
                
                // Notify user about removed properties
                const message = removedSites.length === 1 ? `The property <strong>${removedSites[0]}</strong> has been removed because you no longer have access to it in Search Console.` : removedSites.length === 2 ? `The properties ${removedSites.join(' and ')} have been removed because you no longer have access to them in Search Console.` : `The properties ${removedSites.slice(0, -1).join(', ')}, and ${removedSites[removedSites.length - 1]} have been removed because you no longer have access to them in Search Console.`;
                this.notificationsService.showNotification(message, 'info', 35000, true);
              }
            }
          } catch (error) {
            console.error('Error checking properties access:', error);
          }
        }
        
        return accessibleSites;
      } else {
        this.notificationsService.showNotification(
          'Either the are no properties associated with this account or there was an error when trying to retrieve them',
          'error'
        );
        return [];
      }
    } catch (error) {
      this.handleError(error);
    }
  }

  handleGoogleSignIn(response: any) {
    console.log(response.credential);

    // This next is for decoding the idToken to an object if you want to see the details.
    let base64Url = response.credential.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    console.log(JSON.parse(jsonPayload));
  }

  private init(accessToken: string): Promise<any> {
    console.info('GoogleRequestsService initialize');
    return gapi.client
      .init({
        discoveryDocs: [
          'https://searchconsole.googleapis.com/$discovery/rest?version=v1',
        ],
        clientId: environment.GAPI_CLIENT_ID,
        scope: 'https://www.googleapis.com/auth/webmasters.readonly',
        plugin_name: 'Auth plugin',
      })
      .then(() => {
        console.info('GoogleRequestsService setToken');
        gapi.client.setToken({ access_token: accessToken });
      });
  }

  private handleError(error) {
    if (error.status == 401 || error.status == 403) {
      this.router.navigateByUrl('/login');
    } else {
      console.error('google-requests', error);
      this.notificationsService.showNotification(
        error.result?.error.message,
        'error'
      );
    }
  }

  getKeywordPositionHistoryObject(startDate: Date, data) {
    let currentDate = startDate;
    const keywordHistory: KeywordHistoryChart = {
      series: {
        name: 'Position: ',
        position: [],
        clicks: [],
        impressions: [],
        ctr: [],
      },
      categories: [],
    };

    for (let item of data) {
      if (item.keys[0] === currentDate.toISOString().substring(0, 10)) {
        keywordHistory.categories.push(currentDate);
        if (item.position === 0) {
          keywordHistory.series.position.push('Unranked');
          keywordHistory.series.ctr.push(0);
        } else {
          keywordHistory.series.position.push(
            parseInt(item.position.toFixed(1))
          );
          keywordHistory.series.ctr.push(
            parseFloat((item.ctr * 100).toFixed(2))
          );
        }
      } else {
        while ((item.keys[0] = new Date(item.keys[0])) > currentDate) {
          keywordHistory.categories.push(currentDate);
          keywordHistory.series.position.push(null);
          keywordHistory.series.ctr.push(null);
          currentDate = addDays(currentDate, 1);
        }
      }
      keywordHistory.series.clicks.push(item.clicks);
      keywordHistory.series.impressions.push(item.impressions);
      currentDate = addDays(currentDate, 1);
    }

    return keywordHistory;
  }

  async retrieveKeywordData(
    property: string,
    startDate: string,
    endDate: string,
    dimensions: string[],
    diagramFilters: DiagramFilter[],
    startRow?: number
  ) {
    let allData = [];
    let currentStartRow;
    startRow ? (currentStartRow = startRow) : (currentStartRow = 0);
    let data = await this.getKeywordData(
      property,
      startDate,
      endDate,
      dimensions,
      diagramFilters,
      currentStartRow
    );
    if (!data) {
      return allData;
    } else {
      allData.push(...data);
    }

    while (data.length >= this.rowLimit) {
      currentStartRow += this.rowLimit;
      data = await this.getKeywordData(
        property,
        startDate,
        endDate,
        dimensions,
        diagramFilters,
        currentStartRow
      );
      allData.push(...data);
    }
    return allData;
  }

  public async getKeywordData(
    property: string,
    startDate: string,
    endDate: string,
    dimensions: string[], // dimensions by which to group the results ('query')
    diagramFilters: DiagramFilter[],
    startRow?: number,
    filters?
  ) {
    const keywordData = this.fns.httpsCallable('retrieveKeywordData');
    if (filters) {
      return await keywordData({
        accessToken: this.authService.getAccessToken(),
        property: property,
        startDate: startDate,
        endDate: endDate,
        dimensions: dimensions,
        dimensionFilterGroups: [
          {
            filters: filters,
          },
        ],
        startRow: startRow,
      }).toPromise();
    } else {
      return await keywordData({
        accessToken: this.authService.getAccessToken(),
        property: property,
        startDate: startDate,
        endDate: endDate,
        dimensions: dimensions,
        dimensionFilterGroups: [
          {
            filters: this.getFiltersDto(diagramFilters),
          },
        ],
        startRow: startRow,
      }).toPromise();
    }
  }

  private getFiltersDto(diagramFilters: DiagramFilter[]) {
    return diagramFilters
      .filter((df) => !!df.keyword)
      .map((df) => ({
        dimension: df.filterDimension ?? 'query',
        operator: df.operator ?? 'equals',
        expression: df.keyword,
      }));
  }
}
