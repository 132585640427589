import { Injectable } from '@angular/core';
import {
  GoogleAuthProvider,
  getAuth,
  signOut,
  signInWithPopup,
  UserCredential,
} from 'firebase/auth';
import { Router } from '@angular/router';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  googleDisplayName;
  userCredential: UserCredential;

  constructor(
    private router: Router,
    private notificationsService: NotificationsService
  ) {}

  async authenticate(): Promise<UserCredential> {
    const auth = getAuth();

    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/webmasters.readonly');

    this.userCredential = await signInWithPopup(auth, provider);

    const credentials = GoogleAuthProvider.credentialFromResult(this.userCredential);

    this.setAccessToken(credentials.accessToken);

    return this.userCredential;
  }

  logout() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        this.router.navigateByUrl('/login');
        this.notificationsService.showNotification(
          'You have been logged out successfully',
          'warning'
        );
      })
      .catch((error) => {
        console.error('auth svc', error);
        this.notificationsService.showNotification(
          error.result.error.message,
          'error'
        );
      });
  }

  getAccessToken(): string {
    return localStorage.getItem('access_token');
  }

  private setAccessToken(token: string) {
    localStorage.setItem('access_token', token);
  }
}
