import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import {
  Appearance,
  loadStripe,
  Stripe,
  StripeCardElement,
  StripeElements,
  StripePaymentElement,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import { Observable, catchError, map, of } from 'rxjs';
import { AuthService } from 'src/shared/services/auth.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Property } from 'src/shared/interfaces/property.interface';
import { AuthGuardService } from 'src/shared/services/auth-guard.service';
import { DatePipe, NgIf } from '@angular/common';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BaseLayoutModule } from '../base-layout/base-layout.module';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
  standalone: true,
  imports: [ButtonsModule, NgIf, DatePipe, BaseLayoutModule, DialogsModule],
})
export class SubscriptionComponent implements OnInit {
  @ViewChild('paymentElement') paymentElement: ElementRef;
  @ViewChild('cardInfo') cardInfo: ElementRef;

  afs = inject(AngularFirestore);
  authService = inject(AuthService);
  authGuardService = inject(AuthGuardService);
  fns = inject(AngularFireFunctions);
  router = inject(Router);

  amount = 2500; // 2500 units (cents), which means 25 dollars
  activeSubscriptionId = signal('');
  betaUser = signal(false);
  nextPaymentDate = signal<Date>(null);
  loading = signal(false);
  /**
   * Used to keep the loading state a few more seconds after the subscription was created
   * to improve UX until the Stripe webhook updates the activeSubscriptionId property
   * and then our UI updates the state to display the subscription details
   */
  loadingTimeout;
  initializing = signal(true);
  confirmationDialogOpened = signal(false);

  stripe: Stripe;
  elements: StripeElements;
  payment: StripePaymentElement;
  card: StripeCardElement;

  ngOnInit(): void {
    loadStripe(
      environment.stripePublishableKey
    ).then((value) => {
      this.stripe = value;
      this.elements = this.stripe.elements({
        mode: 'subscription',
        paymentMethodCreation: 'manual',
        currency: 'usd',
        amount: this.amount,
        appearance: {
          theme: 'flat',
        } satisfies Appearance,
      });

      this.payment = this.elements.create('payment', {
        business: {
          name: 'Cogney',
        },
        wallets: {
          applePay: 'never',
          googlePay: 'never',
        },
      } satisfies StripePaymentElementOptions);

      setTimeout(() => {
        this.getUserPropertyData(
          this.authGuardService.getCurrentUser().email
        ).subscribe((p) => {
          this.activeSubscriptionId.set(p.activeSubscriptionId || '');
          clearTimeout(this.loadingTimeout);
          this.loading.set(false);
          this.betaUser.set(!!p.betaUser);
          this.nextPaymentDate.set(
            p.nextPaymentDate ? new Date(p.nextPaymentDate) : null
          );
          this.initializing.set(false);
          setTimeout(() => {
            if (!this.activeSubscriptionId()) {
              this.payment.mount(this.paymentElement.nativeElement);
            }
          });
        });
      }, 1000);
    });
  }

  async handleSubmit(e) {
    this.loading.set(true);
    e.preventDefault();

    const user = this.authGuardService.getCurrentUser();

    this.elements.submit();

    const newPaymentMethod = await this.stripe.createPaymentMethod({
      elements: this.elements,
    });

    const createSubscription = this.fns.httpsCallable('createSubscription');

    createSubscription({
      user: {
        id: user.uid,
        email: user.email,
      },
      paymentMethod: newPaymentMethod.paymentMethod.id,
    })
      .pipe(
        catchError((err) => {
          console.error(err);
          this.loading.set(false);
          return of(err);
        })
      )
      .subscribe(async ({ type, clientSecret }) => {
        if (!type || !clientSecret) {
          this.loading.set(false);
          alert('failed');
          return;
        }

        this.loadingTimeout = setTimeout(() => {
          this.loading.set(false);
        }, 5000);
      });
  }

  cancelActiveSubscription() {
    this.loading.set(true);

    const user = this.authGuardService.getCurrentUser();

    const cancelSubscription = this.fns.httpsCallable('cancelSubscription');

    cancelSubscription({
      user: {
        id: user.uid,
        email: user.email,
      },
      subscriptionId: this.activeSubscriptionId(),
    })
      .pipe(
        catchError((err) => {
          console.error(err);
          this.loading.set(false);
          return of(err);
        })
      )
      .subscribe((resp) => {
        this.loading.set(false);
        setTimeout(() => {
          this.payment.mount(this.paymentElement.nativeElement);
        }, 100);
      });
  }

  closeConfirmationDialog(status: string): void {
    if (status === 'yes') {
      this.cancelActiveSubscription();
    }

    this.confirmationDialogOpened.set(false);
  }

  openConfirmationDialog(): void {
    this.confirmationDialogOpened.set(true);
  }

  back() {
    this.router.navigateByUrl('/properties');
  }

  private getUserPropertyData(email: string): Observable<Property> {
    return this.afs
      .collection<Property>('properties', (ref) =>
        ref.where('userEmail', '==', email)
      )
      .valueChanges()
      .pipe(
        map((documents: Property[]) => {
          if (documents.length < 1) {
            return {
              userEmail: email,
              sitesUrl: [],
            } as Property;
          } else {
            return documents.pop();
          }
        })
      );
  }
}
