<div *ngIf="!tsReady" class="kendo-loader">
  <h3>Page is loading &nbsp;</h3>
  <kendo-loader type="converging-spinner" size="large">
  </kendo-loader>
</div>

<app-base-layout *ngIf="tsReady">
  <div class="mb-4 d-flex justify-content-between align-items-center">
    <h3 class="m-2">Select properties</h3>
    <button kendoButton themeColor="primary" type="submit" class="mx-3 my-3 m-2" *ngIf="gscProperties"
      form="propertiesForm">
      Save</button>
  </div>
  <div class="d-flex">
    <div *ngIf="tsReady">
      <form id="propertiesForm" [formGroup]="form" (ngSubmit)="saveProperties()" novalidate>
        <ul>
          <li *ngFor="let property of gscProperties; let i=index" class="my-2">
            <div class="d-flex text-left">
              <div class="col-auto">
                <kendo-switch [checked]="dbProperties.sitesUrl.includes(property.siteUrl)" size="small"
                  offLabel=" " onLabel=" "
                  (valueChange)="onCheckboxChange(property.siteUrl)">
                </kendo-switch>
              </div>
              <div class="col">
                <span class="px-2">{{property.siteUrl}}</span>
              </div>
            </div>
          </li>
        </ul>
      </form>
    </div>
  </div>
</app-base-layout>
