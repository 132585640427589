import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { initializeApp } from "firebase/app";
import { environment } from 'src/environments/environment';
import posthog from 'posthog-js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  ngOnInit(): void {
    initializeApp(environment.firebaseConfig);
    this.initializePosthog();
  }

  private initializePosthog(): void {
    if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
      posthog.init('phc_ocOom2RLwQDTtyC8tPDtAZxeRPHztXBV0bBqxoKOt6h', { api_host: 'https://app.posthog.com' });
    }
  }
}

