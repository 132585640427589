import { Injectable } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private notificationService: NotificationService) {}

  showNotification(
    content,
    style: 'error' | 'success' | 'none' | 'warning' | 'info',
    duration: number = 3500,
    closable: boolean = false
  ) {
    this.notificationService.show({
      content: content,
      type: { style: style, icon: true },
      cssClass: "button-notification",
      hideAfter: duration,
      animation: { type: 'slide', duration: 600 },
      position: { horizontal: 'center', vertical: 'top' },
      closable: closable,
    });
  }
}
