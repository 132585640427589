import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Utils } from '../../shared/utils';
import { AuthService } from 'src/shared/services/auth.service';
import { AuthGuardService } from 'src/shared/services/auth-guard.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { GoogleRequestsService } from 'src/shared/services/google-requests.service';
import { PropertiesService } from 'src/shared/services/properties.service';
import { getAuth } from 'firebase/auth';
import { Property } from 'src/shared/interfaces/property.interface';
import { NotificationsService } from 'src/shared/services/notifications.service';
import { BehaviorSubject } from 'rxjs';

declare let gapi;

@UntilDestroy()
@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss'],
})
export class BaseLayoutComponent implements OnInit {
  @Output() propertyChange: EventEmitter<string> = new EventEmitter();

  selectedProperty: string;
  initialProperty: string;
  propertiesKeys: string[];
  filteredPropertiesKeys = new BehaviorSubject<string[]>([]);

  googleDisplayName: string;
  gscProperties: { siteUrl: string, permissionLevel: string }[] = [];
  dbProperties: Property;
  confirmDialogOpened = false;
  searchQuery = '';
  mobileMenuOpen = false;
  isSidebarOpen = false;

  constructor(
    private router: Router,
    public authService: AuthService,
    public authGuardService: AuthGuardService,
    private activatedRoute: ActivatedRoute,
    private googleRequestsService: GoogleRequestsService,
    private propertiesService: PropertiesService,
    public notificationsService: NotificationsService,
  ) {}

  ngOnInit(): void {
    this.googleDisplayName = this.authGuardService.getCurrentUser()?.displayName || '';
    console.info('BaseLayoutComponent gapi.load')
    gapi.load('client', () => {
      this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe(async (params) => {
        this.gscProperties = await this.googleRequestsService.listSites();

        const auth = getAuth();
        this.propertiesService.getProperties(auth.currentUser.email).pipe(untilDestroyed(this)).subscribe((properties) => {
          this.dbProperties = properties;
          if (this.dbProperties.sitesUrl.length > 0) {
            this.selectedProperty = params.id ? Utils.parseURL(params.id, true): this.dbProperties.sitesUrl[0];
            this.initialProperty = this.selectedProperty;
          } else {
            this.router.navigateByUrl('/select-property');
          }
          this.propertiesKeys = this.dbProperties.sitesUrl.slice();
          this.filteredPropertiesKeys.next(this.propertiesKeys);
        },
          error => {
            this.notificationsService.showNotification(error.result.error.message, 'error');
          });
      });
    });
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
  }

  handleSearchQueryChange() {
    this.filteredPropertiesKeys.next(this.propertiesKeys.filter(property =>
      property.toLowerCase().includes(this.searchQuery.toLowerCase())
    ));
  }

  resetFilters() {
    this.searchQuery = '';
    this.filteredPropertiesKeys.next(this.propertiesKeys);
  }

  urlContains(url: string) {
    return this.router.url.includes(url) && this.initialProperty === this.selectedProperty;
  }

  navigateTo(url: string, trends?: boolean) {
    if (this.selectedProperty) {
      this.router.navigateByUrl(`/${trends ? 'trends' : url}/${encodeURIComponent(Utils.parseURL(this.selectedProperty))}${ trends ? '/' + url : '' }`);
    } else {
      this.router.navigateByUrl(`/${trends ? 'trends' : url}`);
    }
  }

  getLinkHref(url: string, trends?: boolean) {
    if (this.selectedProperty) {
      return `/${trends ? 'trends' : url}/${encodeURIComponent(Utils.parseURL(this.selectedProperty))}${ trends ? '/' + url : '' }`;
    } else {
      return `/${trends ? 'trends' : url}`;
    }
  }

  getPropertyDomain(property: string) {
    return property.match(/^(?:https?:\/\/)?(?:www\.)?([^\/]+)/)[1];
  }

  handleSelectProperty(property: string) {
    this.selectedProperty = property;
    // this.propertyChange.emit(property);
  }

  handleLogoutClick() {
    this.confirmDialogOpened = true;
  }

  toggleFullSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
  }

  logout() {
    this.authService.logout();
    this.confirmDialogOpened = false;
  }
}
