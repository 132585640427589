export class Utils {
  static dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  static getAvg(array: any[], decimals = 1, isPosition = false) {
    if (isPosition) {
          array = array.filter(i => !!i);
    }

        return array.length ? (array.reduce((acc, v) => {
          return parseFloat(acc) + parseFloat(v ?? 0)
        }, 0) / array.length).toFixed(decimals) : 'Unranked';
  }

  static parseURL(url: string, add = false) {
    if (add) {
      return url.includes('sc-domain') ? url : `https://${url}`;
    } else {
      const parsedUrl = url.split('https://')[1];
      return parsedUrl || url;
    }
  }
}
