import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectPropertyComponent } from './select-property.component';
import { SelectPropertyRoutingModule } from './select-property-routing.module';
import { GridModule as KendoGridModule } from '@progress/kendo-angular-grid';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { BaseLayoutModule } from '../base-layout/base-layout.module';

@NgModule({
  declarations: [SelectPropertyComponent],
  imports: [
    CommonModule,
    SelectPropertyRoutingModule,
    KendoGridModule,
    ReactiveFormsModule,
    FormsModule,
    LoaderModule,
    SwitchModule,
    ButtonsModule,
    BaseLayoutModule
  ],
  exports: [SelectPropertyComponent]
})
export class SelectPropertyModule { }
