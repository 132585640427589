import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Property } from '../interfaces/property.interface';
// import 'rxjs/add/observable/map';

@Injectable({
  providedIn: 'root'
})
export class PropertiesService {
  private propertiesCollection: AngularFirestoreCollection<Property>;

  constructor(private afs: AngularFirestore) {
    this.propertiesCollection = this.afs.collection<Property>('properties');
  }

  getProperties(email: string): Observable<Property> {
    return this.afs.collection<Property>('properties', (ref) => ref.where('userEmail', '==', email))
      .valueChanges()
      .pipe(
        map((documents: Property[]) => {
          if (documents.length < 1) {
            return {
              userEmail: email,
              sitesUrl: []
            } as Property;
          } else {
            return documents.pop();
          }
        })
      );
  }

  saveProperty(property: Property): Promise<void> {
    return this.propertiesCollection.doc(property.userEmail).set(property);
  }

}
