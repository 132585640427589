import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { getAuth, User } from 'firebase/auth';
import { NotificationsService } from './notifications.service';
import posthog from 'posthog-js';
import { PropertiesService } from './properties.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  private currentUser: User;

  constructor(
    private router: Router,
    private propertiesService: PropertiesService
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): any {
    const auth = getAuth();
    return new Promise((resolve, reject) => {
      auth.onAuthStateChanged((user) => {
        if (user && auth.currentUser) {
          this.currentUser = auth.currentUser;

          if (
            !window.location.host.includes('127.0.0.1') &&
            !window.location.host.includes('localhost')
          ) {
            posthog.identify(this.currentUser.uid);
          }

          this.propertiesService
            .getProperties(auth.currentUser.email)
            .subscribe((properties) => {
              if (
                !properties.betaUser &&
                !properties.activeSubscriptionId &&
                state.url !== '/' &&
                !state.url.includes('/subscription')
              ) {
                this.router.navigateByUrl('/subscription');
                resolve(false);
                return;
              }

              resolve(true);
            });
        } else {
          this.router.navigate(['/login']);
          console.error('auth gd', auth);

          resolve(false);
        }
      });
    });
  }

  getCurrentUser(): User {
    return this.currentUser;
  }
}
