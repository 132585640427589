<button
  kendoButton
  fillMode="clear"
  class="property-item"
  [class]="{
    'property-item--active': selected,
    'property-item--collapsed': collapsed,
  }"
  [imageUrl]="faviconUrl"
  (click)="handleSelectProperty()"
  kendoTooltip
  [title]="domain"
  [showOn]="collapsed ? 'hover' : 'none'"
  position="right"
>
{{domain}}
</button>