import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserCredential } from 'firebase/auth';
import { AuthService } from 'src/shared/services/auth.service';
import { NotificationsService } from 'src/shared/services/notifications.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  constructor(
    public authService: AuthService,
    private notificationsService: NotificationsService,
    private router: Router
  ) { }

  login() {
    this.authService.authenticate().then((res: UserCredential)=> {
      this.notificationsService.showNotification(res.user.displayName + ' logged in successfully','success' );
      this.router.navigateByUrl('/rankings');
    }).catch((error) => {
        console.error('login', error);
        this.notificationsService.showNotification(error.result.error.message, 'error');
    });
  }
}


