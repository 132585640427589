import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-property-item',
  templateUrl: './property-item.component.html',
  styleUrls: ['./property-item.component.scss'],
})
export class PropertyItemComponent implements OnInit {
  @Input() domain: string;
  @Input() selected: boolean;
  @Input() collapsed: boolean;
  @Output() select: EventEmitter<string> = new EventEmitter();

  faviconUrl = '';
  defaultFavicon = '../../assets/web.svg';
  
  constructor() {}

  ngOnInit() {
    this.getPropertyFavicon();
  }

  async getPropertyFavicon() {
    const faviconUrl = `https://favicone.com/${ this.removeScDomain(this.domain) }?s=48`;
    this.faviconUrl = await this.imageExists(faviconUrl) ? faviconUrl : this.defaultFavicon;
  }

  imageExists(url: string): Promise<boolean> {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
    });
  }

  handleSelectProperty() {
    this.select.emit();
  }

  private removeScDomain(url: string) {
    return url.replace('sc-domain:', '');
  }
}
