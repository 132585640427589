<app-base-layout>
  <div class="mb-3">
    <button
      *ngIf="activeSubscriptionId() || betaUser()"
      kendoButton
      fillMode="clear"
      icon="chevron-left"
      (click)="back()"
    >
      Back
    </button>
  </div>

  <div class="active-subscription">
    <div class="subscription-details">
      <ng-container *ngIf="activeSubscriptionId(); else subscriptionForm">
        <p class="title">Subscription Details</p>
        <div class="plan">
          <div class="top">
            <div class="type">
              <div class="prefix">
                <div class="text">Standard</div>
              </div>
              <div class="suffix">Plan</div>
            </div>
            <div class="price-container">
              <div class="currency">$</div>
              <div class="value">25</div>
              <div class="per-month">/month</div>
            </div>
          </div>
          <div class="bottom">
            <div class="next-payment-container">
              <div class="text">Next payment</div>
              <div class="date">
                on {{ (nextPaymentDate() | date : "MMMM d, y") || "-" }}
              </div>
            </div>
          </div>
        </div>
        <button
          kendoButton
          fillMode="flat"
          [disabled]="loading()"
          themeColor="primary"
          class="cancel-btn"
          (click)="openConfirmationDialog()"
        >
          {{ loading() ? "Cancelling Subscription..." : "Cancel Subscription" }}
        </button>
        <!-- <p>Active subscription: {{ activeSubscriptionId() || '-'}}</p> -->
      </ng-container>
      <ng-template #subscriptionForm>
        <p *ngIf="!initializing()" class="title">Activate Subscription</p>
        <form (submit)="handleSubmit($event)" class="payment-form">
          <div #paymentElement>
            <!-- Elements will create the card element here -->
          </div>

          <button
            *ngIf="!initializing()"
            kendoButton
            [disabled]="loading()"
            themeColor="primary"
            class="buy-btn"
          >
            {{
              loading()
                ? "Activating Subscription..."
                : "Buy for $" + amount / 100 + ".00"
            }}
          </button>
        </form>
      </ng-template>
    </div>
  </div>

  <kendo-dialog
    title="Please confirm"
    *ngIf="confirmationDialogOpened()"
    (close)="closeConfirmationDialog('cancel')"
    [minWidth]="250"
    [width]="450"
  >
    <p style="margin: 30px; text-align: center">
      Are you sure you want to cancel your active subscription? It will be cancelled immediately and no refund will be made.
    </p>
    <kendo-dialog-actions>
      <button kendoButton (click)="closeConfirmationDialog('no')">No</button>
      <button kendoButton (click)="closeConfirmationDialog('yes')" themeColor="primary">Yes</button>
    </kendo-dialog-actions>
  </kendo-dialog>
</app-base-layout>
