import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseLayoutComponent } from './base-layout.component';
import { BaseLayoutRoutingModule } from './base-layout-routing.module';
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { PropertyItemComponent } from './property-item/property-item.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

@NgModule({
  declarations: [BaseLayoutComponent, PropertyItemComponent],
  imports: [
    CommonModule,
    BaseLayoutRoutingModule,
    ButtonsModule,
    FormsModule,
    DialogModule,
    TextBoxModule,
    TooltipModule,
    ReactiveFormsModule
],
  exports: [BaseLayoutComponent]
})
export class BaseLayoutModule { }
