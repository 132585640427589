import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from 'src/shared/services/auth-guard.service';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('././login/login.module').then((m) => m.LoginModule),
  },
  // {
  //   path: 'properties',
  //   loadChildren: () =>
  //     import('././properties/properties.module').then(
  //       (m) => m.PropertiesModule
  //     ),
  //   canActivate: [AuthGuardService],
  // },
  {
    path: 'select-property',
    loadChildren: () =>
      import('././select-property/select-property.module').then(
        (m) => m.SelectPropertyModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'trends',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('././alerts/alerts.module').then((m) => m.AlertsModule),
      },
      {
        path: ':id',
        loadChildren: () =>
          import('././alerts/alerts.module').then((m) => m.AlertsModule),
      },
    ],
  },
  {
    path: 'rankings',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('././rankings/rankings.module').then((m) => m.RankingsModule),
      },
      {
        path: ':id',
        loadChildren: () =>
          import('././rankings/rankings.module').then((m) => m.RankingsModule),
      },
    ],
  },
  {
    path: 'subscription',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('././subscription/subscription.component').then(
            (m) => m.SubscriptionComponent
          ),
      },
    ],
  },
  {
    path: '',
    loadChildren: () =>
      import('././login/login.module').then((m) => m.LoginModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {bindToComponentInputs: true})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
