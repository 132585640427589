import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { getAuth } from 'firebase/auth';
import { Property } from 'src/shared/interfaces/property.interface';
import { AuthService } from 'src/shared/services/auth.service';
import { GoogleRequestsService } from 'src/shared/services/google-requests.service';
import { NotificationsService } from 'src/shared/services/notifications.service';
import { PropertiesService } from 'src/shared/services/properties.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
declare let gapi;
@UntilDestroy()
@Component({
  selector: 'app-select-property',
  templateUrl: './select-property.component.html',
  styleUrls: ['./select-property.component.scss']
})
export class SelectPropertyComponent implements OnInit {

  form: FormGroup;
  gscProperties: {siteUrl: string, permissionLevel: string}[] = [];
  dbProperties: Property;

  tsReady = false;

  constructor(
    public authService: AuthService,
    public googleRequestsService: GoogleRequestsService,
    public notificationsService: NotificationsService,
    private fb: FormBuilder,
    private propertiesService: PropertiesService,
    private router: Router
  ) {
    this.form = this.fb.group({
      propertiesArray: this.fb.array([])
    })
  }

  async ngOnInit() {
    gapi.load('client', async () => {
      this.gscProperties = await this.googleRequestsService.listSites();
      
      const auth = getAuth();
      this.propertiesService.getProperties(auth.currentUser.email).pipe(untilDestroyed(this)).subscribe((properties: Property) => {
        this.dbProperties = properties;
        this.tsReady = true;
      },
      error => {
        console.error('select-property', error);
        this.notificationsService.showNotification(error.result.error.message,'error')
      })
    });
  }

  onCheckboxChange(siteUrl: string) {
    const index = this.dbProperties.sitesUrl.findIndex(x => x === siteUrl)
    if(index !== -1) {
      this.dbProperties.sitesUrl = this.dbProperties.sitesUrl.filter(x => x !== siteUrl)
    } else {
      this.dbProperties.sitesUrl.push(siteUrl);
    }
  }

  saveProperties() {
    this.propertiesService.saveProperty(this.dbProperties);
    this.router.navigateByUrl('/properties');
  }

}
